import { tv } from '@otomoro/helpers/mergeClasses'
import clsx from "clsx"
import { VariantProps } from 'tailwind-variants'

export const styles = tv({
	slots: {
		section: clsx(
			'mt-5 md:mt-7',
			'[&.highlightedSection:first-child]:mt-0',
		)
	},
})

export type StylesVariants = VariantProps<typeof styles>
