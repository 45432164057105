"use client";

import { QueryResponseInitial, useQuery } from "@sanity/react-loader";
import { SanityDocument } from "next-sanity";

import { homePageQuery } from '@otomoro/sanity/lib/queries/pages/homePage.groq'
import HomePage from "./HomePage";

export default function HomePagePreview({
  initial,
}: {
  initial: QueryResponseInitial<SanityDocument[]>;
}) {
	const { data } = useQuery<SanityDocument[] | null>(
    homePageQuery,
    {},
    { initial }
  );

	return data ? (
    <HomePage data={data as any} />
  ) : (
    <div className="bg-red-100">No posts found</div>
  );
}
