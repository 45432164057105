import { ReactNode } from 'react'
import { AppLink } from '@otomoro/components/shared/AppLink'
import { TextVariants } from '../AppText/AppText.css'

interface LinkExternalProps {
	value: any
	children?: ReactNode
	color?: string
	size?: TextVariants['size']
	className?: string
}

export const LinkExternal: React.FC<LinkExternalProps> = ({
	value,
	children,
	size,
	color,
	className,
}) => {
	const { newWindow, url } = value
	return (
		<>
			{newWindow ? (
				<AppLink
					href={url}
					target="_blank"
					rel="noopener"
					textProps={{ color, size }}
					className={className}
				>
					<>{children}</>
				</AppLink>
			) : (
				<AppLink href={url} textProps={{ color, size }} className={className}>
					<>{children}</>
				</AppLink>
			)}
		</>
	)
}
