import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const styles = tv({
	slots: {
		base: 'mt-5 lg:mt-7 grid grid-cols-4 gap-x-2 lg:grid-cols-6 gap-x-2 lg:gap-x-4 lg:gap-x-4 lg:max-w-[172rem] lg:mx-auto',
		newsWrapper: 'col-span-4 lg:col-span-6 px-2 lg:px-4 mt-2 lg:mt-4',
		newsArticleWrapper:
			'news-link border-t border-black py-2 lg:py-4 group hover:border-mainPink [&.news-link+.news-link]:hover:border-mainPink transition-colors duration-75 flex items-center justify-between relative',
		durationArrowWrapper: 'flex items-center gap-x-3 flex-shrink-0',

		newsTitle: 'group-hover:text-mainPink transition-colors duration-75',
		newsLink:
			'opacity-0 group-hover:opacity-100 transition before:content-normal before:absolute before:top-0 before:left-0 before:right-0 before:bottom-0',

		arrowStyle: 'relative rounded-full bg-mainPink h-[3.2rem] w-[3.2rem]',
		durationStyle:
			'text-mainPink ml-3 flex-shrink-0 hidden lg:block opacity-0 group-hover:opacity-100 transition',
	},
})

export type HNewsVariants = VariantProps<typeof styles>
