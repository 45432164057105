export function formatDate(dateInput?: string): string {
  if (!dateInput) return "";

  const date = new Date(dateInput);
  if (isNaN(date.getTime())) {
    throw new Error(
      "Invalid date format. Please provide a valid date-time string."
    );
  }

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}
