import { tv } from "@otomoro/helpers/mergeClasses";
import { VariantProps } from "tailwind-variants";

export const hero = tv({
	slots: {
		container: "relative h-[100dvh] after:content-[\" \"] after:block after:absolute after:inset-0 after:bg-gradient-to-t from-transparent to-black after:z-1 after:h-[20vh] after:opacity-80",
		image: "w-full h-full object-cover",
	},
})

export type HeroVariants = VariantProps<typeof hero>
