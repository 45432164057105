import { NewsCardProps } from '../../news/types'
import { AppTitle } from '@otomoro/components/shared/AppTitle'
import { AppText } from '@otomoro/components/shared/AppText'
import { SanityImage } from '@otomoro/components/shared/SanityImage'
import { formatDate } from '@otomoro/helpers/formatDate'
import { resolveHref } from '@otomoro/sanity/lib/sanity.links'
import { Button } from '@otomoro/components/shared/Button'
import { Arrow } from '@otomoro/components/icons/Arrow'
import { AppLink } from '@otomoro/components/shared/AppLink'

import { styles } from './FeaturedNewsCard.css'

export type FeaturedNewsCardProps = {
	featuredArticle: NewsCardProps
}

export function FeaturedNewsCard({
	featuredArticle
}: FeaturedNewsCardProps) {

	const { article, title, link, image, figure, content, time, buttons, description } = styles()

	return (
		<article className={article()}>
			<SanityImage
				alt={""}
				image={featuredArticle.image}
				sizes='(max-width: 768px) 100vw, (max-width: 1200px) 60vw, 80vw'
				className={image()}
				figureClass={figure()}
			/>
			<div className={content()}>
				<AppLink
					className={link()}
					href={featuredArticle.link?.url || resolveHref(featuredArticle._type, featuredArticle.slug)}
					target={featuredArticle.link?.url ? '_blank' : '_self'}
					rel={featuredArticle.link?.url ? 'noopener noreferrer' : undefined}
				>
					<AppTitle className={title()} textTransform="uppercase" variant="h2" as="h2">
						{featuredArticle.title}
					</AppTitle>
				</AppLink>
				<AppText as="p" size="base" weight="medium" className={description()}>{featuredArticle.description}</AppText>
				<AppText className={time()} size="base" weight="medium">
					<time dateTime={featuredArticle.publishedAt}>{formatDate(featuredArticle.publishedAt)}</time><span className="inline-block text-[11px]">&#x2022;</span><span>{featuredArticle.duration}</span>
				</AppText>
				<div className={buttons()}>
					<Button as="button" className="hidden lg:block">
						<span>{featuredArticle.link?.title || 'Read more'}</span>
					</Button>
					<Button
						as="button"
						size="singleSmall"
						className="lg:hidden"
						single>
						<Arrow orientation="top-right" color="#131313" />
					</Button>
				</div>
			</div>
		</article>
	)
}
