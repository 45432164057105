import React from 'react'
import { PLogosVariants, styles } from './PartnersLogo.css'
import { SanityImageAssetDocument } from 'next-sanity'
import { LinkExternal } from '../LinkExternal'

interface PartnersLogoProps extends PLogosVariants {
	logos: SanityImageAssetDocument[]
}

export const PartnersLogo = ({ logos }: PartnersLogoProps) => {
	if (!logos) return null
	const { marqueeViewport } = styles()

	return (
		<div className={marqueeViewport()}>
			<ul className="marquee-content flex min-w-full justify-around flex-shrink-0 gap-x-6">
				{logos.map((partnerLogo) => (
					<li key={partnerLogo._id} className="flex items-center">
						<LinkExternal value={partnerLogo.source}>
							<span dangerouslySetInnerHTML={{ __html: partnerLogo.svg }} />
						</LinkExternal>
					</li>
				))}
			</ul>
			<ul
				className="marquee-content flex min-w-full justify-around flex-shrink-0 gap-x-6"
				aria-hidden="true"
			>
				{logos.map((partnerLogo) => (
					<li key={`${partnerLogo._id}-duplicate`} className="flex items-center">
						<LinkExternal value={partnerLogo.source}>
							<span dangerouslySetInnerHTML={{ __html: partnerLogo.svg }} />
						</LinkExternal>
					</li>
				))}
			</ul>
		</div>
	)
}
