import { HighlightedCards } from '@otomoro/components/home/HighlightedCards'
import { CardsSlider } from '@otomoro/components/home/CardsSlider'
import { HighlightedSection } from '../HighlightedSection'
import { KeyFigures } from '../keyFigures'
import { HighlightedNews } from '../HighlightedNews'
import { AlternateSection } from '../AlternateSection'
import { HighlightArticle } from '../HighlightArticle/HighlightArticle'
import clsx from 'clsx'
import { styles } from './FlexibleContent.css'

type HomeFlexibleContentProps = {
	blocks: (
		| Sanity.Default.Schema.Mission
		| Sanity.Default.Schema.CardsSlider
		| Sanity.Default.Schema.HighlightedCards
		| Sanity.Default.Schema.HighlightedSection
	)[]
}

export const HomeFlexibleContent: React.FC<HomeFlexibleContentProps> = ({ blocks }) => {
	const { section } = styles()

	return (
		<div>
			{blocks.map((block: any, index: number) => {
				switch (block._type) {
					case 'highlightedCards':
						return (
							<div key={`fc-${index}`} className={clsx(section(), 'highlightedCards')} id="markets">
								<HighlightedCards
									_key={block._id}
									title={block.title}
									headline={block.headline}
									expandedMarkets={block.expandedMarkets}
									cards={block.cards}
									formOption={block.formOption}
									downloadInfo={block.downloadInfo}
								/>
							</div>
						)
					case 'cardsSlider':
						return (
							<div key={`fc-${index}`} className={clsx(section(), 'cardsSlider')} id="products">
								<CardsSlider
									title={block.title}
									headline={block.headline}
									cardsList={block.products}
									theme={block.darkMode ? 'dark' : 'light'}
								/>
							</div>
						)
					case 'highlightedSection':
						return (
							<div key={`fc-${index}`} className={clsx(section(), 'highlightedSection')}>
								<HighlightedSection
									tag={block.tag}
									headline={block.headline}
									description={block.description}
									button={block.button}
								/>
							</div>
						)
					case 'alternateSection':
						return (
							<div key={`fc-${index}`} className={clsx(section(), 'alternateSection')}>
								<AlternateSection
									_key={block._id}
									tag={block.tag}
									headline={block.headline}
									description={block.description}
									button={block.button}
									image={block.image}
									alternateSide={block.alternateSide}
									theme={block.darkMode ? 'dark' : 'light'}
									descriptionType={block.descriptionType}
									descriptionPairs={block.descriptionPairs}
									showDL={block.showDownload}
									downloadInfo={block.downloadInfo}
									showLogo={block.showLogo}
									cardLogo={block.cardLogo}
								/>
							</div>
						)
					case 'highlightedNews':
						return (
							<div key={`fc-${index}`} className={clsx(section(), 'highlightedSection')}>
								<HighlightedNews
									tag={block.tag}
									headline={block.headline}
									description={block.description}
									news={block.news}
								/>
							</div>
						)
					case 'keyFigures':
						return (
							<div key={`fc-${index}`} className={clsx(section(), 'keyFigures')}>
								<KeyFigures
									tag={block.tag}
									headline={block.headline}
									description={block.description}
									cards={block.cards}
								/>
							</div>
						)
					case 'highlightArticle':
						return (
							<div key={`fc-${index}`} className={clsx(section(), 'highlightArticle')}>
								<HighlightArticle
									tag={block.tag}
									headline={block.headline}
									article={block.article}
									showArticle={block.showArticle}
									partnersLogo={block.partnersLogo}
								/>
							</div>
						)
					default:
						return null
				}
			})}
		</div>
	)
}
