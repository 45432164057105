import { groq } from 'next-sanity'
import { flexibleContent, seoFields } from '@otomoro/sanity/lib/queries/partials/shared'

export const homePageQuery = groq`*[_type == "homePage" && language == "en"][0] {
  pageTitle,
	headline,
  heroImage{
    ...,
    "lqip": asset->metadata.lqip
  },
	${flexibleContent}
  ${seoFields}
}`

export const homePageSeoQuery = groq`*[_type == "homePage" && language == "en"][0]{
  ${seoFields}
}`
