import { PortableTextObject } from 'sanity'
import { styles, HNewsVariants } from './HighlightedNews.css'
import { SectionHeader } from '@otomoro/components/shared/SectionHeader'
import { AppTitle } from '@otomoro/components/shared/AppTitle'
import { resolveHref } from '@otomoro/sanity/lib/sanity.links'
import { Arrow } from '@otomoro/components/icons/Arrow'
import { AppLink } from '@otomoro/components/shared/AppLink'
import { AppText } from '@otomoro/components/shared/AppText'

interface HighlightedNewsProps extends HNewsVariants {
	tag: string
	headline: PortableTextObject
	description?: PortableTextObject
	news: {
		_id: string
		_type: string
		title: string
		slug: string
		duration: string
		link: {
			url: string
			newWindow: boolean
		}
	}[]
}

export const HighlightedNews: React.FC<HighlightedNewsProps> = ({
	tag,
	headline,
	description,
	news,
}) => {
	const {
		base,
		newsWrapper,
		newsArticleWrapper,
		durationArrowWrapper,
		newsTitle,
		newsLink,
		arrowStyle,
		durationStyle,
	} = styles()

	return (
		<section className={base()}>
			<SectionHeader
				tag={tag}
				title={headline}
				description={description}
				descClassNames="hidden lg:block"
			/>
			<div className={newsWrapper()}>
				{news.map((article) => (
					<div key={article._id} className={newsArticleWrapper()}>
						<AppTitle variant="h2" textTransform="uppercase" className={newsTitle()} ellipsis>
							{article.title}
						</AppTitle>
						<div className={durationArrowWrapper()}>
							{article.duration && (
								<AppText size="small" className={durationStyle()}>
									Reading duration: {article.duration}
								</AppText>
							)}
							<AppLink
								className={newsLink()}
								href={article.link ? article.link.url : resolveHref(article._type, article.slug)}
								target={article.link.newWindow ? '_blank' : '_self'}
								rel={article.link.newWindow ? 'noopener' : ''}
							>
								<div className={arrowStyle()}>
									<Arrow orientation="top-right" color="black" />
								</div>
							</AppLink>
						</div>
					</div>
				))}
			</div>
		</section>
	)
}
