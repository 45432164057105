import { PortableTextObject } from 'sanity'
import { styles, ASectionVariants } from './HighlightArticle.css'
import { SanityImageAssetDocument } from 'next-sanity'
import { PartnersLogo } from '@otomoro/components/shared/PartnersLogo/PartnersLogo'
import { FeaturedNewsCard } from "@otomoro/components/shared/FeaturedNewsCard"
import { SectionHeader } from "@otomoro/components/shared/SectionHeader"
import { NewsCardProps } from "@otomoro/components/news/types"

interface HighlightArticleProps extends ASectionVariants {
	tag: string
	headline: PortableTextObject
	article: NewsCardProps
	showArticle: boolean
	partnersLogo: SanityImageAssetDocument[]
}

export const HighlightArticle: React.FC<HighlightArticleProps> = ({
	tag,
	headline,
	article,
	showArticle,
	partnersLogo,
}) => {
	const { articleWrapper, wrapper } = styles()

	return (
		<div className={wrapper()}>
			{showArticle && (
				<section className={articleWrapper()}>
					<SectionHeader tag={tag} title={headline} noPx />
					<FeaturedNewsCard featuredArticle={article} />
				</section>
			)}
			<PartnersLogo logos={partnersLogo} />
		</div>
	)
}
