import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const styles = tv({
	slots: {
		marqueeViewport:
			'marquee mt-5 md:mt-6 w-full relative overflow-hidden flex items-center gap-x-6 flex-shrink-0 after:content-normal after:w-[calc(100%-4rem)] after:md:w-[calc(100%-8rem)] after:left-1/2 after:-translate-x-1/2 after:h-[2px] after:bg-black after:absolute after:bottom-0 before:content-normal before:w-[calc(100%-4rem)] before:md:w-[calc(100%-8rem)] before:left-1/2 before:-translate-x-1/2 before:h-[2px] before:bg-black before:absolute before:top-0 py-[2.8rem] md:py-4',
	},
	variants: {},
})

export type PLogosVariants = VariantProps<typeof styles>
