'use client'

import React, { HTMLAttributes } from 'react'
import { hero, HeroVariants } from './HeroMedia.css'
import { SanityImage } from "../SanityImage"
import { SanityImageObject } from "@sanity/image-url/lib/types/types"
import { PortableTextObject } from "sanity"
import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'
import { SplitText } from "@otomoro/helpers/esm/SplitText";
import clsx from "clsx"
import { Button } from "../Button"

gsap.registerPlugin(SplitText)


interface HeroMediaProps extends HTMLAttributes<Element>, HeroVariants {
	headline: string
	media: SanityImageObject
	align?: 'center' | 'end'
	hasLink?: boolean
}

export const HeroMedia: React.FC<HeroMediaProps> = ({ media, headline, align = "end", hasLink = false }) => {
	const { container, image } = hero()

	useGSAP(() => {
		const tl = gsap.timeline({ defaults: { ease: 'power3.inOut' } })

		const split = new SplitText('.headline', {
      type: 'chars',
    })

		tl.set('.wrapper', {
			opacity: 1,
		})

		tl.from('#line', {
			xPercent: 100,
			duration: 1.8,
		})
		tl.from('.letter', {
			yPercent: 200,
			duration: 1.8,
			stagger: 0.02,
		}, '-=1.7')
		tl.from(split.chars.reverse(), {
      duration: 1.6,
      yPercent: -100,
      stagger: 0.02,
    }, '-=1.7');

		if (hasLink) {
			tl.fromTo('.cta', {
				opacity: 0,
				yPercent: 100,
			}, {
				duration: 1.2,
				opacity: 1,
				yPercent: 0,
			}, '-=1.7')
		}
	}, [])

	return (
		<div className={container()}>
			<SanityImage figureClass="overflow-hidden h-full" className={clsx('image', image())} image={media} alt="" width={1920} height={1080} />
			<div className={`wrapper flex flex-col items-${align} opacity-0 absolute top-1/2 left-1/2 translate-x-[-52%] md:translate-x-[-52%] translate-y-[-50%] w-[80%] md:w-[60%] z-[2]`}>
				<svg viewBox="0 0 929 154" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path className="letter" d="M906.526 1.82593V48.4631H894.688C890.093 48.4631 886.782 47.24 884.848 44.7812C882.913 42.3223 881.847 38.9001 881.63 34.6003L881.027 13.4796C880.919 11.3284 880.717 9.24027 880.409 7.24293C880.102 5.2456 879.388 3.45254 878.273 1.91924H854.242V2.83216C856.285 3.96196 857.616 6.098 858.219 9.17722C858.623 12.2413 858.93 16.6975 859.132 22.5785C859.225 25.5493 859.334 28.2578 859.442 30.6561C859.551 33.0544 859.705 35.314 859.906 37.3567C860.572 43.5001 862.151 47.9865 864.703 50.758C867.255 53.575 871.28 55.3529 876.851 56.175V56.4852C870.629 57.8168 866.034 60.833 863.033 65.4909C860.032 70.1488 858.547 76.1837 858.547 83.5955C858.547 93.1585 861.099 100.26 866.254 104.963C871.361 109.621 878.541 111.972 887.778 111.972H928.691V1.82593H906.533H906.526ZM906.571 95.6425H896.11C885.851 95.6425 880.714 90.5357 880.714 80.3549C880.714 74.3654 882.137 70.1261 885.002 67.6194C887.864 65.1126 891.995 63.829 897.288 63.829L897.334 63.8743H906.571V95.6425Z" fill="white"/>
					<path className="letter" d="M824.096 49.6232H787.178V67.8362H824.096V93.7536H784.934V111.952H846.286V1.82593H783.355V20.0238H824.096V49.6232Z" fill="white"/>
					<g className="letter">
						<path d="M744.036 108.068C750.941 108.068 756.539 102.456 756.539 95.534C756.539 88.6118 750.941 83.0002 744.036 83.0002C737.13 83.0002 731.532 88.6118 731.532 95.534C731.532 102.456 737.13 108.068 744.036 108.068Z" fill="white"/>
						<path d="M744.036 27.7609C750.941 27.7609 756.539 22.1493 756.539 15.2271C756.539 8.30491 750.941 2.69336 744.036 2.69336C737.13 2.69336 731.532 8.30491 731.532 15.2271C731.532 22.1493 737.13 27.7609 744.036 27.7609Z" fill="white"/>
					</g>
					<path className="letter" d="M664.455 29.3522C665.166 25.7156 666.142 22.8533 667.365 20.856C668.589 18.8586 670.074 17.5296 671.761 16.8638C673.448 16.1981 675.289 15.8425 677.223 15.8425C679.576 15.8425 681.665 16.3519 683.445 17.3279C685.286 18.3038 686.756 20.2381 687.931 23.0525C689.107 25.9149 689.974 29.8465 690.531 35.0138C691.089 40.1206 691.399 46.8818 691.399 55.2065C691.399 63.037 691.197 69.6292 690.781 75.0437C690.378 80.4128 689.666 84.7605 688.645 88.1348C687.624 91.5091 686.201 93.9074 684.406 95.3928C682.61 96.8782 680.32 97.6372 677.567 97.6372C672.523 97.6372 669.04 95.7963 667.169 92.1597L667.123 92.0513C665.235 88.4147 664.261 82.5967 664.261 74.567H642.119C642.119 78.6046 642.429 82.9069 642.986 87.4564C643.544 92.0059 644.984 96.3082 647.226 100.237C649.516 104.229 652.935 107.495 657.485 110.047C662.019 112.6 667.837 113.886 676.147 113.886C684.456 113.886 691.094 112.446 695.984 109.646C700.934 106.784 704.649 102.852 707.31 97.839C709.955 92.8255 711.642 86.7906 712.416 79.7344C713.175 72.6782 713.592 65.0646 713.592 56.6793C713.592 48.2941 713.188 40.5721 712.416 33.5612C711.597 26.5529 709.91 20.518 707.31 15.5045C704.664 10.491 700.889 6.60733 695.984 3.94675C691.094 1.33155 684.471 0 676.147 0C668.904 0 662.932 1.1298 658.398 3.37428C653.863 5.61876 650.29 8.79128 647.783 12.7834C645.276 16.7756 643.544 21.4184 642.631 26.7874C641.718 32.1565 641.254 37.7728 641.254 43.7622H663.396C663.396 37.7904 663.752 32.9761 664.463 29.3572L664.455 29.3522Z" fill="white"/>
					<path className="letter" d="M583.667 69.8008H609.523V111.891H631.666V1.7478H609.523V50.6295H583.667V1.7478H561.524V111.891H583.667V69.8008Z" fill="white"/>
					<path className="letter" d="M527.096 49.5627H490.176V67.7581H527.096V93.678H487.916V111.891H549.286V1.7478H486.34V19.9457H527.096V49.5627Z" fill="white"/>
					<path className="letter" d="M428.917 34.1666H429.227L444.931 111.891H477.829V1.7478H457.528V89.638H457.217L437.69 1.7478H420.453L400.909 89.638H400.599V1.7478H380.297V111.891H413.057L428.917 34.1666Z" fill="white"/>
					<path className="letter" d="M366.48 1.7478H344.337V111.889H366.48V1.7478Z" fill="white"/>
					<path className="letter" d="M266.351 50.3821C267.929 52.735 270.081 54.8862 272.726 56.881C275.371 58.8758 278.839 60.8101 283.03 62.6536L300.114 70.0654C304.865 72.0148 307.911 74.1509 309.351 76.4559C310.774 78.7609 311.487 81.6232 311.487 84.9975C311.487 86.6367 311.286 88.2154 310.93 89.7487C310.574 91.2795 309.909 92.659 308.933 93.8341C307.957 95.0093 306.643 95.9853 305.004 96.6965C303.316 97.4077 301.274 97.7632 298.83 97.7632C294.497 97.7632 291.433 96.3232 289.592 93.524C287.751 90.6616 286.838 86.6695 286.838 81.4088H265.624V84.4729C265.624 89.7336 266.492 94.2376 268.177 97.965C269.864 101.695 272.262 104.819 275.263 107.218C278.264 109.679 281.84 111.412 286.016 112.478C290.193 113.545 294.745 114.118 299.632 114.118C310.247 114.118 318.511 111.409 324.576 106.088C330.641 100.719 333.66 92.5959 333.66 81.7014C333.66 77.1519 333.102 73.1598 331.973 69.7401C330.858 66.3204 329.062 63.2412 326.664 60.6411C324.266 58.0259 321.265 55.7815 317.689 53.7866C314.115 51.7893 309.891 49.9963 304.938 48.3696C301.271 47.1465 298.162 45.9083 295.655 44.7331C293.148 43.5579 291.121 42.2263 289.59 40.804C288.059 39.364 286.944 37.7399 286.279 35.9443C285.613 34.1487 285.257 32.0152 285.257 29.5539C285.257 24.896 286.634 21.4763 289.343 19.2797C292.051 17.0832 295.098 15.9534 298.472 15.9534C301.334 15.9534 303.717 16.3569 305.621 17.1765C307.51 17.9961 309.041 19.1259 310.156 20.5507C311.27 21.9907 312.045 23.7687 312.446 25.8744C312.849 27.9626 313.064 30.3306 313.064 32.8828L313.018 33.0366V36.2545H335.16V31.8135C335.16 21.6326 332.452 13.8652 327.098 8.40284C321.744 2.94044 312.259 0.231934 300.283 0.231934C288.306 0.231934 278.96 2.84713 272.229 8.15317C265.498 13.4138 262.124 21.91 262.124 33.5132C262.124 36.8875 262.434 40.0121 263.037 42.766C263.655 45.535 264.769 48.0897 266.348 50.3796L266.351 50.3821Z" fill="white"/>
					<path  className="letter" d="M260.084 111.891V93.678H236.068V1.7478H213.926V93.678H189.958V111.891H260.084Z" fill="white"/>
					<path className="letter" d="M112.975 9.11422C113.379 12.1783 113.686 16.6345 113.888 22.5155C113.997 25.4863 114.09 28.1948 114.198 30.5931C114.307 32.9914 114.446 35.251 114.662 37.2938C115.328 43.4371 116.907 47.9235 119.459 50.695C122.011 53.512 126.036 55.2899 131.607 56.112V56.4222C125.385 57.7538 120.791 60.77 117.79 65.4279C114.789 70.0858 113.303 76.1207 113.303 83.5325C113.303 93.0955 115.855 100.197 121.01 104.9C126.117 109.558 133.297 111.909 142.534 111.909H183.447V1.7478H161.305V48.385H149.467C144.872 48.385 141.561 47.1619 139.627 44.703C137.692 42.2442 136.626 38.822 136.409 34.5222L135.791 13.4014C135.682 11.2503 135.481 9.16214 135.173 7.16481C134.865 5.16748 134.152 3.37442 133.037 1.84111H109.006V2.75403C111.049 3.89897 112.38 6.05014 112.983 9.11422H112.975ZM139.76 67.5589C142.623 65.0521 146.753 63.7685 152.047 63.7685L152.092 63.8139H161.33V95.5669H150.869C140.61 95.5669 135.473 90.4601 135.473 80.2792C135.473 74.3074 136.895 70.0656 139.76 67.5589Z" fill="white"/>
					<g className="letter">
						<path d="M71.2577 69.2914H71.5679L71.5225 69.3368L85.9426 111.891H110.887L82.4296 45.1065V1.7478H60.2875V45.0586L31.8306 111.843H55.8616L71.2577 69.2914Z" fill="white"/>
						<path d="M32.1888 35.0468C32.0804 34.4289 31.9871 33.516 31.9871 32.3862C31.9417 31.8288 31.9417 31.2564 31.9417 30.8075C31.8963 30.3435 31.8333 29.8946 31.8333 29.4759C31.7248 28.2528 31.4298 27.3878 30.9203 26.8153C30.4109 26.258 29.5888 25.9024 28.522 25.6855C29.7451 25.4233 30.6581 24.818 31.2305 23.89C31.7879 22.977 32.0981 21.8018 32.0981 20.3164C32.0981 18.4276 31.5886 17.0506 30.5673 16.1226C29.5459 15.2096 28.169 14.7456 26.328 14.7456H18.4219V36.517H22.7545V27.3575H25.1074C26.0203 27.3575 26.6861 27.6198 27.0417 28.0687C27.3973 28.5327 27.6595 29.1985 27.6595 30.066L27.768 34.212C27.768 34.6155 27.8134 35.0795 27.8764 35.4351C27.9218 35.7907 28.0782 36.1463 28.2799 36.4565H32.9681V36.2547C32.6125 36.0681 32.3023 35.652 32.1939 35.0468H32.1888ZM26.8349 23.489C26.2775 23.953 25.5033 24.2001 24.4366 24.2001H24.482H22.6561V17.9635H24.6988C26.6962 17.9635 27.6999 18.9849 27.6999 20.9797C27.6999 22.1549 27.4048 22.977 26.8324 23.4865L26.8349 23.489Z" fill="white"/>
						<path d="M49.2694 26.3058C49.2694 12.8138 38.2513 1.76538 24.7895 1.76538C11.3277 1.76538 0.30957 12.7507 0.30957 26.3058C0.30957 39.861 11.3277 50.9093 24.7895 50.9093V50.8639C38.3144 50.8488 49.2694 39.8004 49.2694 26.3083V26.3058ZM24.792 45.7395C14.1295 45.7395 5.41891 36.9961 5.41891 26.3058C5.41891 15.6156 14.0841 6.86967 24.792 6.86967C35.4999 6.86967 44.1802 15.613 44.1802 26.3033C44.1802 36.9936 35.452 45.7369 24.792 45.7369V45.7395Z" fill="white"/>
					</g>
					<path id="line" d="M928.68 134.043H31.8306V155.406H928.68V134.043Z" fill="white"/>
				</svg>
				<h1 className={`headline overflow-hidden -tracking-[0.02em] leading-none uppercase font-medium font-condensed text-white text-[8.6vw] md:text-[6.64vw] text-${align}`}>{headline}</h1>
				{hasLink && <Button className="cta opacity-0 !absolute top-[100%] left-[50%] translate-x-[-50%] mt-3" as="nextLink" href="/contact">Contact us</Button>}
			</div>
		</div>
	)
}
