import(/* webpackMode: "eager" */ "/vercel/path0/components/home/CardsSlider/CardsSlider/CardsSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["KeyFigures"] */ "/vercel/path0/components/home/keyFigures/KeyFigures.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/pages/home/HomePage.preview.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/DownloadEmail/DownloadEmail.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroMedia"] */ "/vercel/path0/components/shared/HeroMedia/HeroMedia.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HighlightedCardsSlider"] */ "/vercel/path0/components/shared/HighlightedCardsSlider/HighlightedCardsSlider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-translate/lib/esm/AppDirI18nProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
