import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const styles = tv({
	slots: {
		wrapper: 'flex justify-center items-center flex-col',
		articleWrapper: 'px-2 md:px-4 lg:max-w-[172rem] ',
	},
	variants: {},
})

export type ASectionVariants = VariantProps<typeof styles>
