import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const styles = tv({
  slots: {
    article: "relative lg:col-span-6 lg:grid lg:grid-cols-6 lg:gap-x-4 mt-2 group",
		figure: "col-span-4 rounded-l overflow-hidden aspect-square md:aspect-[3/2]",
    image: "h-full object-cover w-full group-hover:scale-[1.03] group-focus-within:scale-[1.03] transition-transform duration-300",
    content: "col-span-4 lg:col-span-2 border-b-[1.5px] lg:border-b-transparent pb-1 lg:pb-0 gap-x-2 lg:flex lg:flex-col lg:h-full",
		title: "mt-1 col-span-full lg:mt-0 order-0 h-fit",
    link: 'after:z-[2] after:content-[""] after:absolute after:top-0 after:left-0 after:size-full',
    description: "col-span-full mt-1 order-1 lg:order-2 lg:mt-auto max-w-[50ch]",
    time: "col-span-3 lg:col-span-full self-center mt-1 order-2 lg:order-1 inline-flex space-x-1 items-center lg:w-full lg:mt-1",
    buttons: "lg:mt-2 col-span-1 flex justify-end lg:justify-start mt-1 order-3 [&>*]:before:content-[''] [&>*]:before:absolute [&>*]:before:top-0 [&>*]:before:left-0 [&>*]:before:size-full",
  },
})

export type StylesVariants = VariantProps<typeof styles>
